jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
    
    jQuery('.cta_info').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
 
    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });

    $('.video-popup-link').magnificPopup({
        disableOn: 0,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    // mobile submenu toggle
    jQuery('body').on('click', '#desktop_menu .menu-item-has-children > a .dropdown-arrow', function(e) {
        e.preventDefault();
        var $this = jQuery(this),
            $submenu = $this.parent().siblings('.sub-menu');
            $this.toggleClass("fa-angle-down fa-angle-up");
        if(!$this.hasClass("expanded")){
            TweenLite.set($submenu, {
                height: "auto",
                opacity: 1,
            });
            TweenLite.from($submenu, 0.5, {
                height: 0,
                opacity: 0,
                ease: Cubic.easeInOut,
            });
            $this.addClass("expanded");
            $submenu.addClass("expanded");
            TweenMax.to($submenu.find('li'), 1, {opacity: 1});
        } else {
            $this.removeClass("expanded");
            $submenu.removeClass("expanded");
            TweenLite.to($submenu, 0.5, {
                height: 0,
                opacity: 0,
                ease: Cubic.easeInOut,
            });
            TweenMax.to($submenu.find('li'), 1, {opacity: 0});
        }
    });

    //MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;
        jQuery(".col_hamburger_wrapper").toggleClass("is-active");
        jQuery("#hamburger-icon-sticky").toggleClass('is-active');

        if(action == '') {
            jQuery(".header_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {

            
                jQuery(jQuery("#desktop_menu >li.menu-item").get().reverse()).each(function(i) { 
                    TweenLite.to(jQuery(this), 0.2, {delay: 0.1 * i, ease: Power4.easeInOut, opacity:0, y: "-10%"});
                });
                TweenLite.to(jQuery(".header_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0 });
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("#hamburger-icon-sticky").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {

                jQuery("#desktop_menu >li.menu-item").each(function(i) {
                    TweenLite.to(jQuery(this), 0.4, {delay: 0.2, ease: Power4.easeInOut, opacity: 1, y: "0%"});
                });
                // TweenLite.set(jQuery(".header_wrapper"), {ease: Power4.easeInOut, height:"auto", paddingTop: $('header.banner').outerHeight()});
                TweenLite.set(jQuery(".header_wrapper"), {ease: Power4.easeInOut, height:"100vh", paddingTop: '90px'});
                TweenLite.from(jQuery(".header_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0});

                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("#hamburger-icon-sticky").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery(".header_wrapper").removeClass('open');
            jQuery(jQuery("#desktop_menu >li.menu-item").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2, {delay: 0.1 * i, ease: Power4.easeInOut, opacity:0, y: "-10%"});
            });
            TweenLite.to(jQuery(".header_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0 });
            TweenLite.to(jQuery(".header_wrapper .col_social ul li"), 0.5, {ease: Power4.easeInOut, opacity: 0 });

            jQuery("#hamburger-icon").addClass("closed");
            jQuery("#hamburger-icon-sticky").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

   
    
    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").click(function() {
        animateMenu(jQuery(this), '');
    });



    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 100) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });
});